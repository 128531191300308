import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5c5c5c',
      main: '#BA68C8',
      dark: '#0c0c0c',
      contrastText: '#f2f0f1',
    },
    secondary: {
      light: '#5c5c5c',
      main: '#FBC02D',
      dark: '#0c0c0c',
      contrastText: '#f2f0f1',
    },
  },
  typography: {
    body1: {
      color: '#FBC02D',
      // fontSize: '1.05rem',
    },
  },
});

export default theme;
